import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import {
  createContext,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import {
  daysOfWeek,
  fetchOrganizationDirectDepositBankingMapper,
  saveOrganizationDirectDepositBankingMapper,
  saveOrganizationDirectDepositEWAMapper,
  saveOrganizationPayrollSystemSettingsDataMapper,
} from 'dataMappers/organizationsDataMappers';
import { Block } from 'baseui/block';
import { DirectDepositSettingsInitialValues } from 'initialValues/OrganizationInitialValues';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DirectDepositSettingsValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import {
  editOrganizationDirectDepositSettings,
  fetchOrganizationPayrollSystemSettings,
  fetchPayrollSystems,
  organizationAutomaticBankDetailsUpdatesSelector,
  organizationBankingConfigSelector,
  organizationPendingBankingConfigSelector,
  organizationSelector,
  payrollSystemsSelector,
  pendingOrganizationDirectDepositSettingsBankingSelector,
  pendingOrganizationDirectDepositSettingsSelector,
  pendingOrganizationPayrollSystemSettingsSelector,
  pendingPayrollSystemsSelector,
  rejectedOrganizationPayrollSystemSettingsSelector,
  rejectedPayrollSystemsSelector,
  saveOrganizationDirectDepositSettingsBanking,
  saveOrganizationPayrollSystemSettings,
} from 'store/slices/organizations';
import { DirectDepositSettingsValuesType } from 'types/OrganizationTypes';
import { Button, KIND } from 'baseui/button';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { useHistory } from 'react-router-dom';
import {
  ALIGN,
  Radio,
  RadioGroup,
} from 'baseui/radio';
import AppTextarea from 'components/Form/AppTextarea';
import {
  LabelSmall,
  ParagraphSmall,
  ParagraphXSmall,
} from 'baseui/typography';
import {
  characterLimit,
  descriptionText,
  multiLineComponents,
} from 'screens/Organizations/OrganizationListItemHelpers';
import { notificationSelector } from 'store/slices/notification';
import { errorSelector } from 'store/slices/error';
import AppCheckbox from 'components/Form/AppCheckbox';
import AppSelect from 'components/Form/AppSelect';
import Loader from 'components/Loader';
import messages from 'validation/messages';
import AppInput from 'components/Form/AppInput';

const containerStyles = {
  position: 'relative',
} as StyleObject;

export const DirectDepositSettingsFormContext = createContext({} as FormikState<DirectDepositSettingsValuesType>
  & FormikHelpers<DirectDepositSettingsValuesType>
  & FormikHandlers);

const OrganizationFormPayrollDirectDepositSection = () => {
  const { t } = useTranslation(['organizations', 'errors', 'common']);
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const organization = useAppSelector(organizationSelector);
  const organizationBankingConfig = useAppSelector(organizationBankingConfigSelector);
  const organizationBankDetailsUpdates = useAppSelector(organizationAutomaticBankDetailsUpdatesSelector);
  const organizationBankDetailsUpdatesPending = useAppSelector(organizationPendingBankingConfigSelector);
  const payrollSystems = useAppSelector(payrollSystemsSelector);
  const payrollSystemsPending = useAppSelector(pendingPayrollSystemsSelector);
  const rejectedPayrollSystems = useAppSelector(rejectedPayrollSystemsSelector);
  const notificationToast = useAppSelector(notificationSelector);
  const error = useAppSelector(errorSelector);
  const pending = useAppSelector(organizationPendingBankingConfigSelector);
  const pendingOrganizationDirectDepositSettings = useAppSelector(pendingOrganizationDirectDepositSettingsSelector);
  const pendingOrganizationDirectDepositSettingsBanking = useAppSelector(pendingOrganizationDirectDepositSettingsBankingSelector);
  const pendingOrganizationPayrollSystemSettings = useAppSelector(pendingOrganizationPayrollSystemSettingsSelector);
  const rejectedOrganizaitonPayrollSystemSettings = useAppSelector(rejectedOrganizationPayrollSystemSettingsSelector);

  const maxTextLength = '1,024';
  const [ddServiceType, setDDServiceType] = useState('DIRECT_DEPOSIT_DEFAULT');

  const frequency = [
    { value: 'DAILY', label: t('common:frequency.daily') },
    { value: 'WEEKLY', label: t('common:frequency.weekly') },
    { value: 'BI_WEEKLY', label: t('common:frequency.bi-weekly') },
  ];

  const payrollSystemsOptions = useMemo(() => {
    if (!payrollSystems || payrollSystems.length === 0) {
      return [];
    }
    return payrollSystems.map((item: { name: string }) => ({
      value: item.name,
      label: item.name,
    }));
  }, [payrollSystems]);

  const initialValues = {
    ...DirectDepositSettingsInitialValues,
    ...(organizationBankingConfig && fetchOrganizationDirectDepositBankingMapper(organizationBankingConfig)),
    ...(organizationBankingConfig && organizationBankDetailsUpdates && fetchOrganizationDirectDepositBankingMapper({
      ...organizationBankingConfig,
      automaticBankDetailsUpdates: {
        enabled: organizationBankDetailsUpdates?.liveFeedEnabled || null,
        customerCanonicalId: organizationBankDetailsUpdates?.customerCanonicalId || null,
        legalEntityIdentifier: organizationBankDetailsUpdates?.legalEntityIdentifier || null,
        payrollSystemInstance: organizationBankDetailsUpdates?.payrollSystemInstance || '',
      },
    })),
  };

  const onSubmit = (
    values: DirectDepositSettingsValuesType,
  ) => {
    dispatch(editOrganizationDirectDepositSettings({
      organizationID: organization?.id,
      configs: saveOrganizationDirectDepositEWAMapper(values),
    }))
      .unwrap()
      .then(() => {
        dispatch(saveOrganizationDirectDepositSettingsBanking({
          organizationID: organization?.id,
          data: saveOrganizationDirectDepositBankingMapper(
            {
              ...values,
              organizationName: organization?.name || '',
            },
          ),
        }));
        values.directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO' && values.bankDetailsUpdatesEnabled
         && dispatch(saveOrganizationPayrollSystemSettings({
           organizationID: organization?.id,
           data: saveOrganizationPayrollSystemSettingsDataMapper(values),
         }));
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    isValid,
    setValues,
    setFieldValue,
    errors,
    touched,
    setSubmitting,
    setErrors,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleClickCancel = () => {
    history.push('/organizations');
  };

  useEffect(() => {
    setDefaultValues(initialValues);
    setValues(initialValues);
  }, [organizationBankingConfig, organizationBankDetailsUpdates]);

  useEffect(() => {
    const hasOpenNotification = notificationToast?.some((notification) => notification.isOpen === true);

    if (hasOpenNotification) {
      setSubmitting(false);
      setDefaultValues(values);
    }
  }, [notificationToast]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    if (values.directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO'
     && values.automaticFDDReportEnabled
    && !values.automaticFDDReportFrequency[0].value
    ) {
      setErrors({
        ...errors,
        automaticFDDReportFrequency: messages?.required,
      });
    }

    if (values.directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO'
     && values.automaticFDDReportEnabled
    && !values.automaticFDDReportOccurrence[0].value
        && (values.automaticFDDReportFrequency[0].value === 'WEEKLY'
        || values.automaticFDDReportFrequency[0].value === 'BI_WEEKLY')
    ) {
      setErrors({
        ...errors,
        automaticFDDReportOccurrence: messages?.required,
      });
    }
  }, [values, errors]);

  useEffect(() => {
    if (organization?.id) {
      dispatch(fetchOrganizationPayrollSystemSettings({
        organizationID: organization?.id,
      }));
      dispatch(fetchPayrollSystems({
        organizationID: organization?.id,
      }));
    }
  }, [organization, rejectedOrganizaitonPayrollSystemSettings]);

  return (
    <DirectDepositSettingsFormContext.Provider value={formik}>
      <div className={css(containerStyles)}>
        <form onSubmit={handleSubmit}>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Loader active={
              pending
              || pendingOrganizationDirectDepositSettings
              || pendingOrganizationDirectDepositSettingsBanking
              || pendingOrganizationPayrollSystemSettings
              }
            />
            <Cell
              span={12}
              align={ALIGNMENT.start}
            >
              <h3>{t('organizations:directDepositSettings.label')}</h3>
            </Cell>

            <Cell
              span={12}
              align={ALIGNMENT.start}
            >
              <RadioGroup
                name="directDepositSetting"
                error={!!(errors.directDepositSetting && touched.directDepositSetting && errors.directDepositSetting)}
                value={values.directDepositSetting ? values.directDepositSetting[0].value : ddServiceType}
                onChange={(e: any) => {
                  setDDServiceType(e.currentTarget.value);
                  setFieldValue('directDepositSetting', [
                    {
                      value: e.currentTarget.value,
                    },
                  ]);
                }}
                align={ALIGN.vertical}
              >
                <Radio
                  value="DIRECT_DEPOSIT_FUEGO"
                  description={t('organizations:directDepositServiceType.FDD.description')}
                  overrides={{
                    Description: {
                      style: {
                        maxWidth: '860px',
                      },
                    },
                  }}
                >
                  {t('organizations:directDepositServiceType.FDD.label')}
                </Radio>
                { ((values.directDepositSetting[0].value
                    && values.directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO')
                  || ddServiceType === 'DIRECT_DEPOSIT_FUEGO')
                  && (
                    <>
                      <Cell
                        span={[11.8, 11.8, 7.5]}
                        align={ALIGNMENT.start}
                      >
                        <Block
                          marginLeft="16px"
                        >
                          <br />
                          <LabelSmall className={css(multiLineComponents)}>
                            {t('organizations:directDepositServiceType.FDD.termsAndConditions.description')}
                          </LabelSmall>

                          <ParagraphSmall className={css(descriptionText)}>
                            {t('organizations:directDepositServiceType.FDD.termsAndConditions.additionalDescription')}
                          </ParagraphSmall>
                        </Block>
                      </Cell>
                      <Cell
                        span={[12, 12, 7.7]}
                        align={ALIGNMENT.start}
                      >
                        <AppTextarea
                          label={t('organizations:directDepositServiceType.FDD.termsAndConditions.header')}
                          name="fddTermsAndConditions"
                          placeholder={t('common:typeHere')}
                          cellSpan={12}
                          context={DirectDepositSettingsFormContext}
                          textareaProps={{
                            id: 'fddTermsAndConditions',
                            autoComplete: 'off',
                            rows: 8,
                          }}
                          formControlProps={{
                            overrides: {
                              Label: {
                                style: {
                                  'white-space': 'pre-line',
                                  cursor: 'default',
                                },
                              },
                            },
                          }}
                        />
                        <ParagraphXSmall className={css(characterLimit)}>
                          {`${values.fddTermsAndConditions?.length || '0'} / ${maxTextLength} Character Limit`}
                        </ParagraphXSmall>
                        <hr />
                      </Cell>
                      <Cell
                        span={[12, 12, 6]}
                        align={ALIGNMENT.start}
                      >
                        <AppInput
                          name="fddExternalPayrollSystemId"
                          inputProps={{
                            id: 'fddExternalPayrollSystemId',
                            autoComplete: 'off',
                          }}
                          formControlProps={{
                            htmlFor: 'fddExternalPayrollSystemId',
                          }}
                          label={t('directDepositServiceType.FDD.externalPayrollSystemId')}
                          cellSpan={[12, 6, 7]}
                          context={DirectDepositSettingsFormContext}
                        />
                      </Cell>

                      <Grid
                        align={ALIGNMENT.start}
                        gridColumns={12}
                        gridMargins={32}
                        overrides={{
                          GridWrapper: {
                            style: {
                              margin: '0',
                              maxWidth: '920px',
                            },
                          },
                        }}
                      >
                        <Cell span={12}>
                          <h3>{t('organizations:directDepositServiceType.FDD.automaticFDDReport.header')}</h3>
                        </Cell>
                        <AppCheckbox
                          name="automaticFDDReportEnabled"
                          label={t('organizations:directDepositServiceType.FDD.automaticFDDReport.label')}
                          cellSpan={12}
                          context={DirectDepositSettingsFormContext}
                        />
                        <AppTextarea
                          showStar
                          name="automaticFDDReportEmailRecipients"
                          textareaProps={{
                            id: 'automaticFDDReportEmailRecipients',
                            autoComplete: 'off',
                          }}
                          placeholder="john.doe@example.org, doe.john@example.com"
                          label={t('common:emailRecipients.label')}
                          cellSpan={[12, 8, 4]}
                          context={DirectDepositSettingsFormContext}
                          disabled={!values.automaticFDDReportEnabled}
                          cellProps={{
                            overrides: {
                              Cell: {
                                style: {
                                  '@media screen and (min-width: 768px)': {
                                    height: '130px',
                                    display: 'grid !important',
                                  },
                                },
                              },
                            },
                          }}
                        />
                        <AppSelect
                          showStar
                          name="automaticFDDReportFrequency"
                          label={t('common:frequency.label')}
                          cellSpan={[12, 8, 4]}
                          clearable={false}
                          options={frequency}
                          context={DirectDepositSettingsFormContext}
                          selectDisabled={!values.automaticFDDReportEnabled}
                          selectProps={{
                            overrides: {
                              ControlContainer: {
                                props: {
                                  'data-testid': 'select-automaticFDDReport-frequency',
                                  id: 'select-automaticFDDReport-frequency',
                                },
                              },
                              Input: {
                                props: {
                                  'data-testid': 'select-automaticFDDReport-frequency-input',
                                  id: 'select-automaticFDDReport-frequency-input',
                                  autoComplete: 'off',
                                },
                              },
                            },
                          }}
                        />
                        <AppSelect
                          showStar
                          name="automaticFDDReportOccurrence"
                          label={t('common:occurrence.label')}
                          cellSpan={[12, 8, 4]}
                          clearable={false}
                          options={daysOfWeek(t)}
                          context={DirectDepositSettingsFormContext}
                          selectDisabled={!values.automaticFDDReportEnabled || values.automaticFDDReportFrequency[0].value === 'DAILY'}
                          selectProps={{
                            overrides: {
                              ControlContainer: {
                                props: {
                                  'data-testid': 'select-automaticFDDReport-occurrence',
                                  id: 'select-automaticFDDReport-occurrence',
                                },
                              },
                              Input: {
                                props: {
                                  'data-testid': 'select-automaticFDDReport-occurrence-input',
                                  id: 'select-automaticFDDReport-occurrence-input',
                                  autoComplete: 'off',
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <br />
                      <br />
                      {!rejectedOrganizaitonPayrollSystemSettings && (
                        <>
                          <Grid
                            align={ALIGNMENT.start}
                            gridColumns={12}
                            gridMargins={32}
                            overrides={{
                              GridWrapper: {
                                style: {
                                  margin: '0',
                                  maxWidth: '920px',
                                  position: 'relative',
                                },
                              },
                            }}
                          >
                            <Loader active={organizationBankDetailsUpdatesPending} />
                            <Cell span={12}>
                              <h3>{t('organizations:automaticBankDetailsUpdates.header')}</h3>
                            </Cell>
                            <AppCheckbox
                              name="bankDetailsUpdatesEnabled"
                              label={t('organizations:automaticBankDetailsUpdates.enableDataFeed')}
                              cellSpan={12}
                              context={DirectDepositSettingsFormContext}
                            />
                            <AppInput
                              showStar
                              name="bankDetailsUpdatesCustomerCanonicalId"
                              inputProps={{
                                id: 'bankDetailsUpdatesCustomerCanonicalId',
                                autoComplete: 'off',
                                disabled: !values.bankDetailsUpdatesEnabled,
                              }}
                              formControlProps={{
                                htmlFor: 'bankDetailsUpdatesCustomerCanonicalId',
                              }}
                              label={t('organizations:automaticBankDetailsUpdates.customerCanonicalId')}
                              cellSpan={[12, 8, 4]}
                              context={DirectDepositSettingsFormContext}
                            />
                            <AppInput
                              showStar
                              name="bankDetailsUpdatesLegalEntityIdentifier"
                              inputProps={{
                                id: 'bankDetailsUpdatesLegalEntityIdentifier',
                                autoComplete: 'off',
                                disabled: !values.bankDetailsUpdatesEnabled,
                              }}
                              formControlProps={{
                                htmlFor: 'bankDetailsUpdatesLegalEntityIdentifier',
                              }}
                              label={t('organizations:automaticBankDetailsUpdates.legalEntityIdentifier')}
                              cellSpan={[12, 8, 4]}
                              context={DirectDepositSettingsFormContext}
                            />
                            <AppSelect
                              showStar
                              name="bankDetailsUpdatesPayrollSystemInstance"
                              selectLoading={payrollSystemsPending}
                              options={payrollSystemsOptions}
                              formControlProps={{
                                htmlFor: 'bankDetailsUpdatesPayrollSystemInstance',
                              }}
                              label={t('organizations:automaticBankDetailsUpdates.payrollSystemInstance')}
                              cellSpan={[12, 8, 4]}
                              context={DirectDepositSettingsFormContext}
                              selectDisabled={!values.bankDetailsUpdatesEnabled}
                              noResultsText={rejectedPayrollSystems ? t('errors:errorOnLoadingData') : t('errors:noDataAvailable')}
                            />
                          </Grid>
                          <br />
                          <br />
                        </>
                      )}
                    </>
                  )}
                <Radio
                  value="DIRECT_DEPOSIT_CUSTOM"
                  description={t('organizations:directDepositServiceType.DD_CUSTOM.description')}
                  overrides={{
                    Description: {
                      style: {
                        maxWidth: '860px',
                      },
                    },
                  }}
                >
                  {t('organizations:directDepositServiceType.DD_CUSTOM.label') }
                </Radio>
                { ((values.directDepositSetting[0].value
                    && values.directDepositSetting[0].value === 'DIRECT_DEPOSIT_CUSTOM')
                  || ddServiceType === 'DIRECT_DEPOSIT_CUSTOM')
                  && (
                    <Cell
                      span={[12, 12, 7.7]}
                      align={ALIGNMENT.start}
                    >
                      <AppTextarea
                        showStar
                        label={t('organizations:directDepositServiceType.DD.customInstructions.header')}
                        name="ddCustomMessage"
                        placeholder={t('common:typeHere')}
                        cellSpan={12}
                        context={DirectDepositSettingsFormContext}
                        textareaProps={{
                          id: 'ddCustomMessage',
                          autoComplete: 'off',
                          rows: 8,
                        }}
                      />
                      <ParagraphXSmall className={css(characterLimit)}>
                        {`${values.ddCustomMessage.length} / ${maxTextLength} Character Limit`}
                      </ParagraphXSmall>
                    </Cell>
                  )}
                <Radio
                  value="DIRECT_DEPOSIT_DEFAULT"
                  description={t('organizations:directDepositServiceType.DD_DEFAULT.description')}
                  overrides={{
                    Description: {
                      style: {
                        maxWidth: '860px',
                      },
                    },
                  }}
                >
                  {t('organizations:directDepositServiceType.DD_DEFAULT.label')}
                </Radio>
              </RadioGroup>
            </Cell>
          </Grid>
          <hr />

          <Block marginTop="24px">
            <Grid
              align={ALIGNMENT.start}
              gridColumns={12}
            >
              <Cell
                span={12}
              >
                <Block
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Block
                    display="inline-flex"
                    marginRight="16px"
                  >
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      onClick={handleClickCancel}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: 'OrganizationFormDirectDepositSection-cancel',
                          },
                        },
                      }}
                    >
                      {t('common:cancel')}
                    </Button>
                  </Block>

                  <Button
                    type="submit"
                    kind={KIND.primary}
                    disabled={isSubmitting || !isFormChanged || !isValid}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'OrganizationFormDirectDepositSection-save',
                        },
                      },
                    }}
                  >
                    {t('common:save')}
                  </Button>
                </Block>
              </Cell>
            </Grid>
          </Block>
        </form>
      </div>
    </DirectDepositSettingsFormContext.Provider>
  );
};

export default memo(OrganizationFormPayrollDirectDepositSection);
